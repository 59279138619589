@import url('https://fonts.googleapis.com/css?family=Raleway:100&display=swap');

body, html {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
