h1 {
  margin: min(10vmin, 10px);
}

h2 {
  margin-top: min(10vmin, 10px);
}

.App {
  text-align: center;
  height: 100vh;
  width: 100vw;
  background-color: #292d35;
  overflow: hidden;
}

.NameAndTitle {
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.AboutMeSection {
  width: 100%;
  display: flex;
  gap: calc(15px + 1vw);
  align-items: center;
  justify-content: center;
  margin-bottom: calc(5px + 3vmin);
}

.AboutMeText {
  /*padding-left: max(12vw, 2px);*/
  /*padding-right: max(12vw, 2px);*/
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: calc(9px + 0.5vmin);
  text-align: justify;
  text-justify: distribute;
  color: #dbdbdb;
  width: 70%;
  max-width: 600px;
}

.ProfilePic {
  height: 100px;
  width: 100px;
  border-radius: 30%;
  filter: drop-shadow(0 0 0.5rem black);
}

.App-logo {
  height: max(5vmin, 20px);
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.AttributionContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.AttributionText {
  padding-left: 20px;
}

.LogoBox {
  padding-right: 8px;
}

.ContentBox {
  padding-top: 3vmin;
}

.NavBar {
  padding-left: max(10vw, 2px);
  padding-right: max(10vw, 2px);
  padding-bottom: 1vh;
}

.ButtonFrame {
  width: clamp(100px, 20vmin, 200px);
  height: min(8vh, 13px + 7vmin);
  padding-top: 20px;
}

.ButtonFrame a {
  width: inherit;
  height: inherit;
}

.NavButton {
  font-family: 'Raleway', 'Consolas';
  font-size: calc(15px + 1.2vmin);
  cursor: pointer;
  background-color: transparent;
  border-radius: 1px;
  border-color: white;
  border-width: 1px;
  outline: none;
  color: white;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  position: relative;
  width: inherit;
  height: inherit;
  transition: 1s ease-in-out;
}

.NavButton:hover {
  background-color: #121418;
}

.ButtonText {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.border {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  fill: none;
  stroke: rgb(236, 250, 255);
  stroke-width: 1;
  stroke-dasharray: 100 550;
  stroke-dashoffset: 100;
  transition: 1s ease-in-out;
  z-index: 1;
}

.border:hover {
  stroke-dasharray: 700;
  stroke-dashoffset: -600;
}

.ContactInfo {
  margin-bottom: 5vmin;
}

.LetsConnect {
  font-style: italic;
  font-size: calc(9px + 1vmin);
  margin-bottom: 5px;
  margin-top: 2vh;
}

.ContactDetails {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: calc(9px + 1vmin);
  color: #dbdbdb;
}

.diagonalBox {
  margin-top: 2vh;
  margin-bottom: 2vh;
  background-image: linear-gradient(45deg, #242424, #232529, #242424);
  transform: skewY(-4deg);
}

.diagonalContent { 	
	max-width: 50em;
  margin: 0 auto; 
  padding: 5px;
  transform: skewY(6deg);
}

/* Formatting to handle NavBar resizing */
/* Forces equal row lengths. */
@media screen 
  and (min-width: 1250px) {
  .NavBar {
    padding-left: 3px;
    padding-right: 3px;
  }
}

@media screen
  and (min-width: 1000px) 
  and (max-width: 1249px) {
  .NavBar {
    padding-left: 18vw;
    padding-right: 18vw;
  }
}

/* Mobile Formatting */
@media screen 
  and (max-width: 412px) {
  .App {
    overflow: auto;
  }
  .AboutMeSection {
    flex-direction: column;
    gap: calc(5px + 0.5vw);
    margin-bottom: calc(2px + 0.25vmin);
  }
  .ProfilePic {
    height: 20%;
    width: 20%;
    min-height: 40px;
    min-width: 40px;
  }
  .AboutMeText {
    padding-left: calc(2px + 1vw);
    padding-right: calc(2px + 1vw);
    width: 95%;
  }
  .NavBar {
    padding-left: 2vw;
    padding-right: 2vw;
    padding-bottom: 1vh;
  }
  .NavButton {
    width: 80vw;
  }
  .LetsConnect {
    margin-top: 1vh;
  }
  .border {
    stroke-dasharray: 100 720;
    stroke-dashoffset: 100;
  }
  .border:hover {
    stroke-dasharray: 1000;
    stroke-dashoffset: -720;
  }
}
